export default {
        "config": ["serverConfig/getServerConfig"],
        "assets": ["assets/getAssets"],
        "units": ["units/getUnits"],
        "unitsNext": ["unitsNext/getUnits"],
        "heroes": ["heroes/getHeroes"],
        "bosses": ["bosses/getBosses"],
        "spawner": ["spawners/getSpawner"],
        "news": ["news/getNews", "assets/getAssets"],
        "mails": ["mails/getMails", "assets/getAssets", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
        "shop": ["shop/getShopContent", "assets/getAssets", "chests/getChests", "units/getUnits", "heroes/getHeroes"],
        "promotions": ["promotions/getPromotions", "assets/getAssets", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
        "promotionsIndex": ["promotions/getPromotions"],
        "specialOffers": ["promotions/getPromotions", "assets/getAssets", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
        "specialOffersIndex": ["promotions/getPromotions"],
        "botDecks": ["botDecks/getBotDecks", "units/getUnits", "heroes/getHeroes","resources/getResources"],
        "leaderbots": ["leaderbots/getLeaderbots", "botDecks/getBotDecks", "serverConfig/getLeaderBotConfig"],
        "mirrorDecks": ["mirrorDecks/getMirrorDecks", "units/getUnits", "heroes/getHeroes"],
        "gameModeDecks": ["gameModeDecks/getGameModeDecks", "units/getUnits", "heroes/getHeroes"],
        "wiki": ["wiki/getWiki", "assets/getAssets"],
        "rewards": ["rewards/getRewards", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
        "rewardsIndex": ["rewards/getRewards", "road/getExperienceRoad"],
        "experienceRewards": ["road/getExperienceRoad"],
        "tips": ["resources/getResources"],
        "season": ["seasons/getSeasons", "assets/getAssets"],
        "seasonContent": ["seasons/getSeasonPassContent", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
        "trophyRoad": ["road/getTrophyRoad", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
        "leaderboard": ["leaderboard/getTrophyLeaderboard", "assets/getAssets"],
        "mockUsers": ["mockUsers/getMockUsers"],
        "events": ["events/getEvents", "units/getUnits", "heroes/getHeroes", "chests/getChests", "assets/getAssets", "serverConfig/getMiniTournamentConfig"],
        "panelAdmin": ["panelAdmin/getPanelUsers"],
        "promoCodes": ["promoCodes/getPromoCodes"],
        "promoCodesAdd": ["promoCodes/getPromoCodes", "units/getUnits", "heroes/getHeroes", "chests/getChests"],
        "users": ["units/getUnits", "heroes/getHeroes", "chests/getChests" ],
        "quests": ["serverConfig/getQuests"],
        "localization": ["localization/getLocalization"],
        "factionsIndex": ["factionSystem/getFactions"],
        "skillsIndex": ["skills/getSkills"],
        "factionsAdd": ["assets/getAssets", "units/getUnits", "factionSystem/getFactions"],
        "skillsAdd": ["assets/getAssets", "units/getUnits", "factionSystem/getFactions", "skills/getSkills"],
        "factionsConfig": ["serverConfig/getFactionsConfig", "factionSystem/getFactions", "skills/getSkills"],
        "dynamicDifficulty": ["serverConfig/getDynamicDifficultyConfig", "units/getUnits"],
        "modifiers": ["gameModifiers/getGameModifiers", "serverConfig/getMatchmakingConfig", "assets/getAssets"],
        "coop": ["serverConfig/getCoopAfkDetectorConfig"]
}