export default {
    REWARD_TYPES: [
        {
            text: 'GOLD',
            value: 0
        },
        {
            text: 'GEM',
            value: 1
        },
        {
            text: 'TOKEN',
            value: 2
        },
        {
            text: 'CHEST',
            value: 3
        },
        {
            text: 'HERO',
            value: 4
        },
        {
            text: 'UNIT',
            value: 5
        },
        {
            text: 'RANDOM_UNIT',
            value: 6
        }
    ],
    CARD_REWARD_TYPES: [
        {
            text: 'SYSTEM_DETERMINED',
            value: 0
        },
        {
            text: 'SPECIFIC',
            value: 1
        },
        {
            text: 'RANDOM',
            value: 2
        }
    ],
    CARD_OWNERSHIP_TYPES: [
        {
            text: "BOTH",
            value: 0
        },
        {
            text: "OWNED",
            value: 1
        },
        {
            text: "NOT_OWNED",
            value: 2
        }
    ],
    CARD_RARITY: [
        {
            text: 'COMMON',
            value: 0
        },
        {
            text: 'RARE',
            value: 1
        },
        {
            text: 'EPIC',
            value: 2
        },
        {
            text: 'LEGENDARY',
            value: 3
        }
    ],
    CONDITION_TYPES: [
        {
            text: "TROPHY_BASED",
            value: 0
        },
        {
            text: "PURCHASE_BASED",
            value: 2
        },
        {
            text: "PURCHASE_HISTORY_BASED",
            value: 3
        }
    ],
    CONDITION_OPERATORS: [
        [
            {
                text: 'GREATER_OR_EQUAL_TO',
                value: ">=",
                available_on: [0, 1, 2]
            },
            {
                text: 'GREATER_THAN',
                value: ">",
                available_on: [0, 1, 2]
            },
            {
                text: 'EQUAL_TO',
                value: '==',
                available_on: [0, 1, 2]
            },
            {
                text: "LESS_THAN",
                value: "<",
                available_on: [0, 1, 2]
            },
            {
                text: "LESS_THAN_OR_EQUAL_TO",
                value: "<=",
                available_on: [0, 1, 2]
            },
            {
                text: "CONTAINS",
                value: "ARRAY_CONTAINS",
                available_on: [3]
            },
            {
                text: "DOES_NOT_CONTAIN",
                value: "ARRAY_DOES_NOT_CONTAIN",
                available_on: [3]
            }
        ]
    ],
    REWARD_CLAIM_TYPES: [
        {
            text: 'IMMEDIATE',
            value: 0
        },
        {
            text: 'DAILY',
            value: 1
        }
    ],
    REWARD_DAY_OPTIONS: [
        {
            text: 'IMMEDIATE (0 days)',
            value: 0
        },
        {
            text: '1 days',
            value: 1
        },
        {
            text: '2 days',
            value: 2
        },
        {
            text: '3 days',
            value: 3
        },
        {
            text: '4 days',
            value: 4
        },
        {
            text: '5 days',
            value: 5
        }
    ],
    PROMO_TYPE_OPTIONS: [
        {
            text: 'Default',
            value: 0
        },
        {
            text: 'Gem Promo',
            value: 2
        }
    ],
    POPUP_TYPE_OPTIONS: [
        {
            text: 'Small',
            value: 0
        }, 
        {
            text: 'Big',
            value: 1
        }
    ],
    LAYOUT_OPTIONS: [
        {
            text: 'Centered Layout (3 items)',
            value: 0,
        },
        {
            text: 'Subheader Layout (3 items)',
            value: 1,
        },
        {
            text: 'Single Unit Layout (1 item)',
            value: 2,
        },
        {
            text: "Leader's Pack Layout (6 items)",
            value: 3,
        },
        {
            text: 'Triple Unit Layout (3 items)',
            value: 4,
        },
        {
            text: 'Triple Chest Layout (5 items)',
            value: 5,
        },
        {
            text: 'Single Resource Layout (1 item)',
            value: 6,
        },
        {
            text: 'Multiple Resource Layout (2 items)',
            value: 7,
        },
        {
            text: 'Single Chest Layout (1 item)',
            value: 8
        },
        {
            text: 'Unit with resource (3 items)',
            value: 9
        },
        {
            text: 'Advance Pack Layout (5 items)',
            value: 10
        },
        {
            text: 'Blank Layout',
            value: 11
        }
    ],
    PROMOTION_TYPES: {
        DEFAULT: 0,
        SPECIAL_OFFER: 1,
        IN_GAME_CURRENCY_PROMO: 2,
        ABILITY_PACK: 3
    },
    SPECIAL_OFFER_TYPE_OPTIONS: [
        {
            text: 'Special Offer',
            value: 1
        },
        {
            text: 'Legendary Ability Pack',
            value: 3
        }
    ],
    ABILITY_PACK_TRIGGER_TYPES: [
        {
            text: "New Legendary",
            value: "NEW_LEGENDARY"
        },
        {
            text: "Level 8 Legendary Upgrade",
            value: "LVL8_LEGENDARY"
        },
        {
            text: "Level 10 Legendary Upgrade",
            value: "LVL10_LEGENDARY"
        },
        {
            text: "Level 10 Legendary + 3 Cards",
            value: "LVL10.5_LEGENDARY"
        },
    ],
    PROMO_ARENA_OPTIONS: [
        {
            text: "Arena 2",
            value: 2
        },
        {
            text: "Arena 3",
            value: 3
        },
        {
            text: "Arena 4",
            value: 4
        },
        {
            text: "Arena 5",
            value: 5
        },
        {
            text: "Arena 6",
            value: 6
        },
        {
            text: "Arena 7",
            value: 7
        },
        {
            text: "Arena 8",
            value: 8
        },
        {
            text: "Arena 9",
            value: 9
        },
        {
            text: "Arena 10",
            value: 10
        },
        {
            text: "Arena 11",
            value: 11
        },
        {
            text: "League",
            value: 12
        },
    ]
}