export default {
    languages: [
        {
            code: 'en',
            name: 'English'
        },
        {
            code: 'fr',
            name: 'French'
        },
        {
            code: 'de',
            name: 'German'
        },
        {
            code: 'it',
            name: 'Italian'
        },
        {
            code: 'es',
            name: 'Spanish'
        },
        {
            code: 'pt',
            name: 'Porteguese'
        },
        {
            code: 'tr',
            name: 'Turkish'
        }
    ],
    localizableObjects: [
        "units",
        "heroes",
        "bosses",
        "promotions",
        "special-offers",
        "shop"
    ]
}