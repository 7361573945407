<template>
  <div class="main-container">
    <h1 class="main-heading">{{ $t("SPECIAL_OFFER") }}</h1>

    <div class="content-container">
      <div class="col">
        <div class="form-container">
          <p>{{ "Offer Type" }}</p>
          <select v-model="type">
            <option
              v-for="offerType in promotionConstants.SPECIAL_OFFER_TYPE_OPTIONS"
              v-bind:key="offerType.value"
              :value="offerType.value"
            >
              {{ offerType.text }}
            </option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ "Language" }}</p>
          <select v-model="selectedLanguage">
            <option
              v-for="language in languages"
              v-bind:key="language.code"
              :value="language.code"
            >
              {{ language.name }}
            </option>
          </select>
        </div>
        <div class="form-container" v-if="type === promotionConstants.PROMOTION_TYPES.SPECIAL_OFFER">
          <p>{{ 'Arena Begin' }}</p>
          <select v-model="currentPromotion.arena_begin">
            <option
              v-for="arenaOption in promotionConstants.PROMO_ARENA_OPTIONS"
              v-bind:key="arenaOption.value"
              :value="arenaOption.value"
            >
              {{ arenaOption.text }}
            </option>
          </select>
        </div>
        <div class="form-container" v-if="type === promotionConstants.PROMOTION_TYPES.SPECIAL_OFFER">
          <p>{{ 'Arena End' }}</p>
          <select v-model="currentPromotion.arena_end">
            <option
              v-for="arenaOption in promotionConstants.PROMO_ARENA_OPTIONS"
              v-bind:key="arenaOption.value"
              :value="arenaOption.value"
            >
              {{ arenaOption.text }}
            </option>
          </select>
        </div>
        <div class="form-container" v-if="type === promotionConstants.PROMOTION_TYPES.ABILITY_PACK">
          <p>{{ "Trigger Type" }}</p>
          <select v-model="currentPromotion.trigger">
            <option
              v-for="triggerType in promotionConstants.ABILITY_PACK_TRIGGER_TYPES"
              v-bind:key="triggerType.value"
              :value="triggerType.value"
            >
              {{ triggerType.text }}
            </option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.EXPIRES_IN`) }}</p>
          <input
            v-model.number="expiresInDays"
            type="number"
            max="100"
            min="0"
          />
        </div>
        <div class="form-container">
          <p>{{ "Display Priority (Lower is higher)" }}</p>
          <input
            v-model.number="currentPromotion.priority"
            type="number"
            max="10000"
            min="0"
          />
        </div>
      </div>
    </div>
    <div class="content-container">
      <div class="col">
        <div class="form-container">
          <p>{{ $t(`TITLE`) }}</p>
          <input v-model="currentPromotion.title" />
        </div>
        <div class="form-container">
          <p>{{ "Localized Title" }}</p>
          <input
            v-model="currentPromotion.localizables.title[selectedLanguage]"
          />
        </div>

        <div class="form-container">
          <p>{{ "Product ID" }}</p>
          <input v-model="currentPromotion.product_id" />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.DISCOUNT_RATE`) }}</p>
          <input
            v-model.number="currentPromotion.discount_rate"
            type="number"
            max="100"
            min="0"
          />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.VALUE_MULTIPLIER`) }}</p>
          <input
            v-model.number="currentPromotion.value_multiplier"
            type="number"
            max="100"
            min="0"
          />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.PRICE`) }}</p>
          <input
            v-model.number="currentPromotion.price"
            type="number"
            max="100"
            min="0"
          />
        </div>
        <div class="form-container">
          <p>{{ "One Time Purchase"  }}</p>
            <input v-model="currentPromotion.one_time_purchase" type="checkbox"/>
        </div>
        <div class="form-container">
          <p>{{ "Reward Type" }}</p>
          <select v-model="currentPromotion.reward_claim_type">
            <option
              v-for="option in promotionConstants.REWARD_CLAIM_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ option.text || "IMMEDIATE" }}
            </option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ "Layout" }}</p>
          <select v-model="currentPromotion.layout_index">
            <option
              v-for="option in promotionConstants.LAYOUT_OPTIONS"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ option.text || "DEFAULT" }}
            </option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ "Popup Type" }}</p>
          <select v-model="currentPromotion.popup_type">
            <option
              v-for="option in promotionConstants.POPUP_TYPE_OPTIONS"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ option.text || "DEFAULT" }}
            </option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.SHOP_BANNER_ASSET`) }}</p>
          <multiselect
            v-model="currentPromotion.shop_banner_asset"
            :options="assetOptions"
            :multiple="false"
          />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.POPUP_BANNER_ASSET`) }}</p>
          <multiselect
            v-model="currentPromotion.popup_banner_asset"
            :options="assetOptions"
            :multiple="false"
          />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.MINI_BANNER_ASSET`) }}</p>
          <multiselect
            v-model="currentPromotion.mini_banner_asset"
            :options="assetOptions"
            :multiple="false"
          />
        </div>
        <div class="col">
      <h2 style="text-align: start;">{{ $t(`PROMOTION_VALUES.CONDITIONS`) }}</h2>
      <div class="action-buttons-table" v-if="currentPromotion.conditions.length > 0">
        <div class="row header-row condition-row">
          <p>{{ $t(`PROMOTION_VALUES.CONDITION_TYPE`) }}</p>
          <p>{{ $t(`PROMOTION_VALUES.CONDITION_OPERATOR`) }}</p>
          <p>{{ $t('PROMOTION_VALUES.CONDITION_VALUE') }}</p>
          <p>{{ $t('ACTIONS') }}</p>
        </div>
        <div v-for="(reward, index) in currentPromotion.conditions" v-bind:key="index" class="row condition-row">
          <select v-model.number="currentPromotion.conditions[index].type">
            <option v-for="option in promotionConstants.CONDITION_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('PROMOTION_VALUES.' + option.text) }}</option>
          </select>
          <select v-model.number="currentPromotion.conditions[index].operator">
            <option :disabled="!option.available_on.includes(currentPromotion.conditions[index].type)" v-for="option in promotionConstants.CONDITION_OPERATORS[0]" :value="option.value" v-bind:key="option.value">{{ $t('PROMOTION_VALUES.' + option.text) }}</option>
          </select>
          <input v-model="currentPromotion.conditions[index].value"/>
          <button @click="currentPromotion.conditions.splice(index, 1)" class="error">{{ $t('REMOVE') }}</button>
        </div>
      </div>
      <button @click="addCondition" class="success">{{ $t('PROMOTION_VALUES.ADD_CONDITION') }}</button>
    </div>
      </div>
      <div class="col" v-if="isExperimentActive">
        <h2 style="text-align: start">{{ "B Category" }}</h2>
        <div class="form-container">
          <p>{{ $t(`TITLE`) }}</p>
          <input v-model="currentPromotion.B.title" />
        </div>
        <div class="form-container">
          <p>{{ "Product ID" }}</p>
          <input v-model="currentPromotion.B.product_id" />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.DISCOUNT_RATE`) }}</p>
          <input
            v-model.number="currentPromotion.B.discount_rate"
            type="number"
            max="100"
            min="0"
          />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.VALUE_MULTIPLIER`) }}</p>
          <input
            v-model.number="currentPromotion.B.value_multiplier"
            type="number"
            max="100"
            min="0"
          />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.PRICE`) }}</p>
          <input
            v-model.number="currentPromotion.B.price"
            type="number"
            max="100"
            min="0"
          />
        </div>
        <div class="form-container">
          <p>{{ "Reward Type" }}</p>
          <select v-model="currentPromotion.B.reward_claim_type">
            <option
              v-for="option in promotionConstants.REWARD_CLAIM_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ option.text || "IMMEDIATE" }}
            </option>
          </select>
        </div>

        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.SHOP_BANNER_ASSET`) }}</p>
          <multiselect
            v-model="currentPromotion.B.shop_banner_asset"
            :options="assetOptions"
            :multiple="false"
          />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.POPUP_BANNER_ASSET`) }}</p>
          <multiselect
            v-model="currentPromotion.B.popup_banner_asset"
            :options="assetOptions"
            :multiple="false"
          />
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.MINI_BANNER_ASSET`) }}</p>
          <multiselect
            v-model="currentPromotion.B.mini_banner_asset"
            :options="assetOptions"
            :multiple="false"
          />
        </div>
      </div>
    </div>
    <div class="col">
      <h2 style="text-align: start">
        {{
          isExperimentActive
            ? $t(`PROMOTION_VALUES.REWARDS_A`)
            : $t(`PROMOTION_VALUES.REWARDS`)
        }}
      </h2>
      <p>Layout display order will be same with here on client</p>
      <div
        class="action-buttons-table"
        v-if="currentPromotion.rewards.length > 0"
      >
        <div class="row header-row">
          <p>{{ $t(`PROMOTION_VALUES.TYPE`) }}</p>
          <p>{{ $t(`PROMOTION_VALUES.REWARD_TYPE`) }}</p>
          <p>{{ $t("PROMOTION_VALUES.OWNERSHIP_TYPE") }}</p>
          <p>{{ $t("PROMOTION_VALUES.RARITY") }}</p>
          <p>{{ $t("PROMOTION_VALUES.CARD") }}</p>
          <p>{{ $t("PROMOTION_VALUES.CHEST_ID") }}</p>
          <p>{{ $t("PROMOTION_VALUES.ARENA") }}</p>
          <p>{{ $t("PROMOTION_VALUES.AMOUNT") }}</p>
          <p v-if="currentPromotion.reward_claim_type == 1">
            {{ $t("PROMOTION_VALUES.REWARD_DAY") }}
          </p>
          <p>{{ $t("ACTIONS") }}</p>
        </div>
        <div
          v-for="(reward, index) in currentPromotion.rewards"
          v-bind:key="index"
          class="row"
        >
          <select
            v-model.number="currentPromotion.rewards[index].type"
            @change="
              initializeRewardType(index, currentPromotion.rewards[index].type)
            "
          >
            <option
              v-for="option in promotionConstants.REWARD_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("PROMOTION_VALUES." + option.text) }}
            </option>
          </select>
          <select
            v-model.number="currentPromotion.rewards[index].selection_type"
            :disabled="![4, 5].includes(currentPromotion.rewards[index].type)"
          >
            <option
              v-for="option in promotionConstants.CARD_REWARD_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("PROMOTION_VALUES." + option.text) }}
            </option>
          </select>
          <select
            v-model.number="currentPromotion.rewards[index].ownership_type"
            :disabled="
              ![4, 5].includes(currentPromotion.rewards[index].type) ||
              currentPromotion.rewards[index].selection_type === 1
            "
          >
            <option
              v-for="option in promotionConstants.CARD_OWNERSHIP_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("PROMOTION_VALUES." + option.text) }}
            </option>
          </select>
          <select
            v-model.number="currentPromotion.rewards[index].rarity"
            @change="clearPool(index)"
            :disabled="
              ![4, 5, 6].includes(currentPromotion.rewards[index].type) ||
              currentPromotion.rewards[index].selection_type === 1
            "
          >
            <option
              v-for="option in promotionConstants.CARD_RARITY"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("PROMOTION_VALUES." + option.text) }}
            </option>
          </select>
          <multiselect
            v-if="currentPromotion.rewards[index].type === 6"
            v-model="currentPromotion.rewards[index].pool"
            :options="
              units
                .filter(
                  (x) =>
                    x.status === 1 &&
                    x.rarity === currentPromotion.rewards[index].rarity
                )
                .map((y) => {
                  return { id: y.id, name: y.name };
                })
            "
            label="name"
            track-by="name"
            :multiple="true"
          />
          <select
            v-else
            v-model="currentPromotion.rewards[index].card_id"
            :disabled="
              ![4, 5].includes(currentPromotion.rewards[index].type) ||
              currentPromotion.rewards[index].selection_type === 0
            "
          >
            <option
              v-for="option in currentPromotion.rewards[index].type === 5
                ? units.filter((x) => x.status === 1)
                : heroes.filter((x) => x.status === 1)"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <select
            v-model="currentPromotion.rewards[index].chest_id"
            :disabled="currentPromotion.rewards[index].type !== 3"
          >
            <option
              v-for="option in chests"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            v-model.number="currentPromotion.rewards[index].arena"
            :disabled="currentPromotion.rewards[index].type !== 3"
          />
          <input v-model.number="currentPromotion.rewards[index].amount" />
          <select
            v-if="currentPromotion.reward_claim_type == 1"
            v-model="currentPromotion.rewards[index].reward_day"
          >
            <option
              v-for="option in promotionConstants.REWARD_DAY_OPTIONS"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <button
            @click="currentPromotion.rewards.splice(index, 1)"
            class="error"
          >
            {{ $t("REMOVE") }}
          </button>
        </div>
      </div>
      <button @click="addReward('a')" class="success">
        {{ $t("PROMOTION_VALUES.ADD_REWARD") }}
      </button>
    </div>
    <div v-if="isExperimentActive" class="col">
      <h2 style="text-align: start">{{ $t(`PROMOTION_VALUES.REWARDS_B`) }}</h2>
      <div
        class="action-buttons-table"
        v-if="currentPromotion.rewards_b.length > 0"
      >
        <div class="row header-row">
          <p>{{ $t(`PROMOTION_VALUES.TYPE`) }}</p>
          <p>{{ $t(`PROMOTION_VALUES.REWARD_TYPE`) }}</p>
          <p>{{ $t("PROMOTION_VALUES.OWNERSHIP_TYPE") }}</p>
          <p>{{ $t("PROMOTION_VALUES.RARITY") }}</p>
          <p>{{ $t("PROMOTION_VALUES.CARD") }}</p>
          <p>{{ $t("PROMOTION_VALUES.CHEST_ID") }}</p>
          <p>{{ $t("PROMOTION_VALUES.ARENA") }}</p>
          <p>{{ $t("PROMOTION_VALUES.AMOUNT") }}</p>
          <p v-if="currentPromotion.B.reward_claim_type == 1">
            {{ $t("PROMOTION_VALUES.REWARD_DAY") }}
          </p>
          <p>{{ $t("ACTIONS") }}</p>
        </div>
        <div
          v-for="(reward, index) in currentPromotion.rewards_b"
          v-bind:key="index"
          class="row"
        >
          <select
            v-model.number="currentPromotion.rewards_b[index].type"
            @change="
              initializeRewardTypeB(
                index,
                currentPromotion.rewards_b[index].type
              )
            "
          >
            <option
              v-for="option in promotionConstants.REWARD_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("PROMOTION_VALUES." + option.text) }}
            </option>
          </select>
          <select
            v-model.number="currentPromotion.rewards_b[index].selection_type"
            :disabled="![4, 5].includes(currentPromotion.rewards_b[index].type)"
          >
            <option
              v-for="option in promotionConstants.CARD_REWARD_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("PROMOTION_VALUES." + option.text) }}
            </option>
          </select>
          <select
            v-model.number="currentPromotion.rewards_b[index].ownership_type"
            :disabled="
              ![4, 5].includes(currentPromotion.rewards_b[index].type) ||
              currentPromotion.rewards_b[index].selection_type === 1
            "
          >
            <option
              v-for="option in promotionConstants.CARD_OWNERSHIP_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("PROMOTION_VALUES." + option.text) }}
            </option>
          </select>
          <select
            v-model.number="currentPromotion.rewards_b[index].rarity"
            :disabled="
              ![4, 5].includes(currentPromotion.rewards_b[index].type) ||
              currentPromotion.rewards_b[index].selection_type === 1
            "
          >
            <option
              v-for="option in promotionConstants.CARD_RARITY"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("PROMOTION_VALUES." + option.text) }}
            </option>
          </select>
          <select
            v-model="currentPromotion.rewards_b[index].card_id"
            :disabled="
              ![4, 5].includes(currentPromotion.rewards_b[index].type) ||
              currentPromotion.rewards_b[index].selection_type === 0
            "
          >
            <option
              v-for="option in currentPromotion.rewards_b[index].type === 5
                ? units.filter((x) => x.status === 1)
                : heroes.filter((x) => x.status === 1)"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <select
            v-model="currentPromotion.rewards_b[index].chest_id"
            :disabled="currentPromotion.rewards_b[index].type !== 3"
          >
            <option
              v-for="option in chests"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            v-model.number="currentPromotion.rewards_b[index].arena"
            :disabled="currentPromotion.rewards_b[index].type !== 3"
          />
          <input v-model.number="currentPromotion.rewards_b[index].amount" />
          <select
            v-if="currentPromotion.B.reward_claim_type == 1"
            v-model="currentPromotion.rewards_b[index].reward_day"
          >
            <option
              v-for="option in promotionConstants.REWARD_DAY_OPTIONS"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <button
            @click="currentPromotion.rewards_b.splice(index, 1)"
            class="error"
          >
            {{ $t("REMOVE") }}
          </button>
        </div>
      </div>
      <button @click="addReward('b')" class="success">
        {{ $t("PROMOTION_VALUES.ADD_REWARD") }}
      </button>
    </div>
    <div class="buttons-section">
      <button @click="submit" class="info">{{ $t("SUBMIT") }}</button>
      <template v-if="$route.query.id">
        <button
          v-if="env === 'staging' || env === 'prod'"
          class="info"
          @click="() => exportTo('dev')"
        >
          Export to Development
        </button>
        <button
          v-if="env === 'dev' || env === 'prod'"
          class="info"
          @click="() => exportTo('staging')"
        >
          Export to Staging
        </button>
        <button
          v-if="env === 'dev' || env === 'staging'"
          class="info"
          @click="() => exportTo('prod')"
        >
          Export to Production
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import {
  onMounted,
  computed,
  reactive,
  watch,
  ref,
  onBeforeMount,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import specialOfferSchema from "@/schemas/specialOffer";
import { useRoute, useRouter } from "vue-router";
import promotionConstants from "@/constants/promotionConstants";
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import languageConstants from "@/constants/languagesTemp";
import dispatchMap from "@/constants/dispatchMap";

export default {
  name: "SpecialOfferAdd",
  setup() {
    const store = useStore();
    const usedPages = ["specialOffers"];
    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr);
        });
      });
    });

    const type = ref(1);
    const units = computed(() => store.getters["units/getUnits"]);
    const promotions = computed(
      () => store.getters["promotions/getPromotions"]
    );
    const heroes = computed(() => store.getters["heroes/getHeroes"]);
    const chests = computed(() => store.getters["chests/getChests"]);
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined,
        },
        ...store.getters["assets/getAssets"].files,
      ];
    });
    const assetOptions = computed(() => {
      let assetArray = []
      assetList.value.forEach(asset => {
        if(asset.doc_ref === undefined){
          assetArray.push("DEFAULT BANNER")
        }
        else{
          assetArray.push(asset.doc_ref)
        }
      })
      return assetArray
    });
    const expiresInDays = ref(1);
    const isExperimentActive = ref(false);
    const isExperimentButtonEnabled = ref(false);
    const selectedLanguage = ref("en");
    const languages = languageConstants.languages;

    const currentPromotion = reactive({ ...specialOfferSchema() });
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      if (route.query.id && promotions.value.length > 0) {
        const editingPromotion = JSON.parse(
          JSON.stringify(promotions.value.find((x) => x.id === route.query.id))
        );
        type.value = editingPromotion.type;
        Object.keys(editingPromotion).forEach((key) => {
          currentPromotion[key] = editingPromotion[key];
        });
        if (!currentPromotion.localizables)
          currentPromotion.localizables = { title: {} };
        expiresInDays.value = editingPromotion.expires_in / (60 * 60 * 1000);
      }
      store.dispatch("loader/loadingStatus", false);
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["units/getUnits"],
      (units) => {
        currentPromotion.rewards.forEach((x) => {
          if (x.type === 6) {
            x.pool = x.pool.map((y) => {
              return { id: y, name: units.find((z) => z.id === y).name };
            });
          }
        });
      }
    );

    watch(
      () => store.getters["promotions/getPromotions"],
      (promotions) => {
        if (route.query.id && promotions.length > 0) {
          const editingPromotion = JSON.parse(
            JSON.stringify(promotions.find((x) => x.id === route.query.id))
          );
          type.value = editingPromotion.type;
          Object.keys(editingPromotion).forEach((key) => {
            currentPromotion[key] = editingPromotion[key];
          });
          expiresInDays.value = editingPromotion.expires_in / (60 * 60 * 1000);
          if (!currentPromotion.localizables)
            currentPromotion.localizables = { title: {} };

          if (editingPromotion.rewards_b) {
            isExperimentActive.value = true;
          }
        } else isExperimentButtonEnabled.value = true;
      }
    );

    const addReward = (category) => {
      console.log(category);
      if (category == "a") currentPromotion.rewards.push({});
      if (category == "b") currentPromotion.rewards_b.push({});
    };

    const addCondition = () => {
      currentPromotion.conditions.push({});
    };

    const initializeRewardType = (index, type) => {
      if (type === 0 || type === 1 || type === 2) {
        currentPromotion.rewards[index] = {
          type,
        };
      } else if (type === 3) {
        currentPromotion.rewards[index] = {
          type,
          chest_id: "",
          arena: 1,
        };
      } else if (type === 6) {
        currentPromotion.rewards[index] = {
          type,
          rarity: 0,
          amount: 1,
          pool: [],
        };
      } else {
        currentPromotion.rewards[index] = {
          type,
          reward_type: 0,
        };
      }
    };

    const initializeRewardTypeB = (index, type) => {
      if (type === 0 || type === 1 || type === 2) {
        currentPromotion.rewards_b[index] = {
          type,
        };
      } else if (type === 3) {
        currentPromotion.rewards_b[index] = {
          type,
          chest_id: "",
          arena: 1,
        };
      } else {
        currentPromotion.rewards_b[index] = {
          type,
          reward_type: 0,
        };
      }
    };

    const onExperimentButtonClick = () => {
      isExperimentActive.value = !isExperimentActive.value;
    };

    const checkDataFields = (data) => {
      const obj = specialOfferSchema();
      delete obj.reward_claim_type;
      delete obj.B;
      delete obj.rewards_b;

      const keys = Object.keys(obj);

      for (let i = 0; i < keys.length; i++) {
        if (['arena_begin', 'arena_end', 'trigger'].includes(keys[i]))
          continue
        if (data[keys[i]] == undefined) {
          return false;
        }
      }
      return true;
    };

    const clearPool = (index) => {
      if (currentPromotion.rewards[index].type) {
        currentPromotion.rewards[index].pool = [];
      }
    };

    const formatRewardsOnSubmit = (data) => {

      if (data.type === promotionConstants.PROMOTION_TYPES.SPECIAL_OFFER) {
        delete data.trigger
      }

      if (data.type === promotionConstants.PROMOTION_TYPES.ABILITY_PACK) {
        delete data.arena_begin
        delete data.arena_end
      }

      if (data.reward_claim_type === 0) {
        data.rewards.forEach((reward) => {
          delete reward.reward_day;
        });
      }

      if (data.B.reward_claim_type === 0) {
        data.rewards_b.forEach((reward) => {
          delete reward.reward_day;
        });
      }

      data.rewards.forEach((x) => {
        if (x.type === 6) {
          x.pool = x.pool.map((y) => y.id);
        }
      });
    };

    const submit = () => {
      const data = {
        ...JSON.parse(JSON.stringify(currentPromotion)),
        expires_in: expiresInDays.value * 60 * 60 * 1000,
        type: type.value,
      };

      formatRewardsOnSubmit(data);

      if (!isExperimentActive.value) {
        delete data.B;
        delete data.rewards_b;
      } else
        data.experiment_id =
          "experiment_test_" + Math.floor(Math.random() * 1000).toString();

      const isValid = checkDataFields(data);
      if (!isValid) {
        return notify({
          title: i18n.global.t("ERROR"),
          text: i18n.global.t("NOTIF.ERROR.EMPTY_FIELDS"),
          type: "error",
        });
      }

      if (route.query.id) {
        store.dispatch("promotions/updatePromotion", {
          id: route.query.id,
          data: { ...data, update_date: Date.now() },
        });
      } else {
        store
          .dispatch("promotions/addPromotion", {
            data: { ...data, update_date: Date.now() },
          })
          .then((res) => {
            if (res) {
              router.push({ name: "PromotionAdd", query: { id: res.id } });
            }
          });
      }
    };

    const exportTo = (environment) => {

      const data = {
        ...JSON.parse(JSON.stringify(currentPromotion)),
      }

      if(!isExperimentActive.value) {
        delete data.B;
        delete data.rewards_b;
      } 

      const isValid = checkDataFields(data);
      if (!isValid) {
        return notify({
          title: i18n.global.t("ERROR"),
          text: i18n.global.t("NOTIF.ERROR.EMPTY_FIELDS"),
          type: "error",
        });
      }
      store.dispatch("promotions/exportPromotion", {
        id: route.query.id,
        data: { ...data, update_date: Date.now() },
        environment,
      });
    };

    const appConfig = computed(() => store.getters["auth/getAppConfig"]);
    const env = appConfig.value.appEnv || "dev";

    return {
      currentPromotion,
      submit,
      exportTo,
      env,
      promotionConstants,
      type,
      addReward,
      addCondition,
      units,
      heroes,
      initializeRewardType,
      initializeRewardTypeB,
      onExperimentButtonClick,
      expiresInDays,
      chests,
      assetList,
      assetOptions,
      isExperimentActive,
      isExperimentButtonEnabled,
      selectedLanguage,
      languages,
      clearPool,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  min-height: 150px;
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 4fr;
}

.form-container {
  display: flex;
  margin: 15px 0;
}

.form-container p {
  width: 60%;
  text-align: start;
}
.form-container input,
.form-container div,
.form-container label,
.form-container select {
  width: 40%;
}
#creation_date,
#expiration_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.col {
  padding: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 2fr 2fr 2fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}

.row.condition-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.row.header-row {
  background-color: #cccccc;
  font-weight: bold;
}
.form-container.inline {
  display: flex;
  flex-direction: column;
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.form-info {
  color: gray;
  font-size: 0.75rem;
  text-align: start;
}
</style>