export default () => {
    return {
        title: 'Pack Title',
        localizables: {
            title: {}
        },
        product_id: '',
        expires_in: 0,
        price: 0,
        discount_rate: 0,
        arena_begin: 2,
        arena_end: 4,
        trigger: "",
        reward_claim_type: 0,
        rewards: [],
        conditions: [],
        rewards_b: [],
        value_multiplier: 0,
        layout_index: 0,
        popup_type: 0,
        priority: 0,
        one_time_purchase: 0,
        B: {
            title: 'Pack Title B',
            localizables: {
                title: {}
            },
            product_id: '',
            price: 0,
            discount_rate: 0,
            value_multiplier: 0,
            reward_claim_type: 0,
            layout_index: 0,
            popup_type: 0,
        }
    }
}